import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/underground/1.jpeg'
import pic02 from '../assets/images/underground/2.jpeg'
import pic03 from '../assets/images/underground/3.jpeg'
import pic04 from '../assets/images/underground/321.jpeg'
import pic05 from '../assets/images/underground/4321.jpeg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Underground = (props) => (
    <Layout>
        <Helmet>
            <title>Underground Tanks</title>
            <meta name="Underground Tanks" content="Landing Page" />
        </Helmet>

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Underground Tanks</h2>
                    </header>
                </div>
            </section>

            <Slider {...settings}>
                <div className="image">
                    <img src={pic03} alt="" />
                </div>
                <div className="image">
                    <img src={pic02} alt="" />
                </div>
                <div className="image">
                    <img src={pic01} alt="" />
                </div>
                <div className="image">
                    <img src={pic04} alt="" />
                </div>
                <div className="image">
                    <img src={pic05} alt="" />
                </div>
            </Slider>

            <section id="two" className="spotlights">
                <section>
                    {/* <div className="image">
                        <img src={pic01} alt="" />
                    </div> */}
                    <div className="content">
                        <div className="inner">
                            <p>All below ground double skinned storage tanks supplied by the Marcon Tanks are constructed from steel to a design that meets the requirements for environmental guidance and legislation. Below ground double skinned tanks are also suitable for storing petrol, diesel, gas oil and other associated oil products.<br /><br />
                            Below ground fuel storage tanks are available to suit a wide range of applications and can be supplied with storage capacities from as little as 100 litres up to those able to contain in excess of 200,000 litres.  <br /><br />
                            As part of the overall package of installation services provided by Marcon Tanks can also supply and install all necessary pipework from the underground tank. This pipework includes, fill pipes, vent pipes and any associated vapour recovery connections, pump suction or pressure pipework.</p>   
                        </div>    
                        </div>          
                       
                </section>
            </section>
        </div>

    </Layout>
)

export default Underground